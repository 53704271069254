import React, { useContext } from "react";
import { Fragment, useState, useEffect } from "react";
import {
  CubeIcon,
  ComputerDesktopIcon,
  RectangleGroupIcon,
  AdjustmentsHorizontalIcon,
  FolderIcon,
  DocumentIcon,
  UserCircleIcon,
  BeakerIcon,
  QuestionMarkCircleIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { BrowserRouter as Router, Route, Link, useLocation } from "react-router-dom";
import urlJoin from "url-join";
import { Dialog, Transition } from "@headlessui/react";
import { useModal } from "./modals/useModal";
import { CALENDLY_LINK, DOCS_LINK } from "../globals";
import Cookies from "js-cookie";
import { TenantSwitcher } from "./TenantSwitcher";
import { tenantedRouteBuilder } from "../utils/tenantManager";

interface LocationState {
  pathname: string;
  search: string;
}

interface SidebarButtonProps {
  name: string;
  href?: string;
  onClick?: () => void;
  Icon: any;
  selectedIf?: (location: LocationState) => boolean;
}

const Sidebar: React.FC = () => {
  const modalProvider = useModal();

  if (!Cookies.get("SEEN_INTRO_MODAL")) {
    Cookies.set("SEEN_INTRO_MODAL", "true", { expires: 365 });
    modalProvider.openModal("HELP_MODAL", null);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const SidebarButton: React.FC<SidebarButtonProps> = (item) => {
    const location = useLocation<LocationState>();
    let styleSupplement: string = "";

    if (item?.selectedIf && item?.selectedIf(location as LocationState)) {
      styleSupplement = "bg-neutral";
    }

    const item_style = [
      "group flex flex-row items-center justify-start p-4 px-8 text-sm rounded-none hover:bg-neutral text-primary w-full font-semibold",
      styleSupplement,
    ].join(" ");

    if (item.href != null) {
      if (item.href.startsWith("http") || item.href.startsWith("mailto")) {
        return (
          <a key={item.name} href={item.href} className={`link-reset ${item_style}`}>
            <item.Icon className="mr-4 w-5 h-5 " aria-hidden="true" />
            <span className="">{item.name}</span>
          </a>
        );
      } else {
        return (
          // You need to use a link here so that navigation can be blocked by react-router-dom in case of unsaved changes
          // <div key={item.name} onClick={() => routeNavigator(item.href)} className={`link-reset ${item_style}`}>
          <Link key={item.name} to={item.href as string} className={`link-reset ${item_style}`}>
            <item.Icon className="mr-4 w-5 h-5 " aria-hidden="true" />
            <span className="">{item.name}</span>
          </Link>
          // </div>
        );
      }
    } else if (item.onClick != null) {
      return (
        <button key={item.name} onClick={item.onClick} className={item_style}>
          <item.Icon className="mr-4 w-5 h-5 " aria-hidden="true" />
          <span className="">{item.name}</span>
        </button>
      );
    }
    return null;
  };

  const topMenuItems: SidebarButtonProps[] = [
    {
      name: "Playground",
      href: tenantedRouteBuilder("/playground"),
      selectedIf: (location) => !location.search.includes("mode=eval") && location.pathname.includes("/playground"),
      Icon: RectangleGroupIcon,
    },
    {
      name: "Test Suite",
      href: tenantedRouteBuilder("/playground?mode=eval"),
      Icon: BeakerIcon,
      selectedIf: (location) => location.search.includes("mode=eval"),
    },
  ];

  const libraryItems: SidebarButtonProps[] = [
    {
      name: "Apps",
      href: tenantedRouteBuilder("/project"),
      selectedIf: (location) => location.pathname.includes("project"),
      Icon: ComputerDesktopIcon,
    },
    {
      name: "Test sets",
      href: tenantedRouteBuilder("/testset"),
      selectedIf: (location) => location.pathname.includes("testset"),
      Icon: FolderIcon,
    },
    {
      name: "Evaluations",
      href: tenantedRouteBuilder("/evaluation"),
      selectedIf: (location) => location.pathname.includes("evaluation"),
      Icon: AdjustmentsHorizontalIcon,
    },
  ];

  const bottomMenuItems: SidebarButtonProps[] = [
    {
      name: "Docs",
      href: DOCS_LINK,
      Icon: DocumentIcon,
    },
    { name: "Help", onClick: () => modalProvider.openModal("HELP_MODAL", null), Icon: QuestionMarkCircleIcon },
    {
      name: "Profile",
      href: "/profilesettings",
      Icon: UserCircleIcon,
    },
  ];

  return (
    // N.B. shrink-0 here is critical! It stops the sidebar from shrinking when the window is resized
    <div className="flex flex-col h-full bg-mainlightgray text-titlebrown border-r shrink-0 w-48 justify-start items-center shadown-inner">
      {/* <Branding viewMode="both" height={30} href="" className="p-8" /> */}
      <img
        src={urlJoin(import.meta.env.BASE_URL, "/logo.svg")}
        className="mr-4 h-6 m-2 my-8 mt-6 hover:cursor-pointer"
        alt="Composo logo"
        onClick={() => {
          window.location.href = "/";
        }}
      />
      <div className="flex flex-col flex-grow justify-between items-start w-full pb-4">
        <div className="w-full">
          <TenantSwitcher />
          <div className="w-full mt-2">{topMenuItems.map((item) => SidebarButton(item))}</div>
          <div className="mt-4 divider"></div>
          <h4 className="ml-4 mb-2">Library</h4>
          <div className="w-full">{libraryItems.map((item) => SidebarButton(item))}</div>
        </div>
        <div className="w-full">
          {/* <div className="divider"></div> */}
          <div className="w-full">{bottomMenuItems.map((item) => SidebarButton(item))}</div>
        </div>

        {/* Modal */}
        <Transition show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10" onClose={closeModal}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-titlebrown p-6">
                    Talk to us!
                  </Dialog.Title>
                  <button onClick={closeModal} className="absolute top-6 right-6">
                    <XCircleIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                  <Dialog.Description as="p" className="text-sm text-maindarkgray px-6">
                    We're here to help! You can either send us an email or book a call.
                  </Dialog.Description>
                  <div className="p-6 flex flex-col items-center">
                    <a
                      href="mailto:seb@composo.ai"
                      className="link-reset btn btn-primary mb-4 px-14"
                      onClick={closeModal}
                    >
                      Email Us
                    </a>
                    <a
                      href={CALENDLY_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-reset btn btn-outline px-10"
                      onClick={closeModal}
                    >
                      Book a call
                    </a>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useMemo, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ConversationHistory } from "../parameter_editors/ConversationHistory";
import { TemperatureSlider } from "../parameter_editors/TemperatureSlider";
import { AutoPromptIcon } from "../AutoPrompt";
import { parameterNameProcessor } from "../../utils/utils";
import { TitleBlock } from "./TitleBlock";
import { NameBlock } from "./NameBlock";
import {
  FloatParameterType,
  IntegerParameterType,
  MultiChoiceStringParameterType,
  ParameterTypes,
  StringParameterType,
  VarSourceType,
} from "../../utils/interfaces";
import { GetParameterMatchFn, SetParameterMatchFn } from "../../utils/useParameterMatches";

const UI_PARAMETER_ORDER = [
  "MULTI-CHOICE",
  "CONSTRAINED_FLOAT",
  "CONSTRAINED_INTEGER",
  "FLOAT",
  "INTEGER",
  "SYSTEM_MESSAGE",
  "TEXT",
  "CONVERSATIONHISTORY",
];

export const ParameterTitle: React.FC<{ value: string }> = ({ value }) => {
  return <div className="text-maindarkgray text-sm capitalize">{parameterNameProcessor(value)}</div>;
};

export interface PlaygroundParameterBlockProps {
  advancedMode: boolean;
  name: string;
  parameters: ParameterTypes[];
  docstring: string | null;
  last_active: string | null;
  is_live: boolean;
  getParameterMatch: GetParameterMatchFn;
  setParameterMatch: SetParameterMatchFn;
  uniqueTestcaseFields: string[] | null;
  appTestsetLinks: any;
  setAppTestsetLinks: (links: any) => void;
}

export const ParameterBlock: React.FC<PlaygroundParameterBlockProps> = ({
  advancedMode,
  name,
  parameters,
  docstring,
  last_active,
  is_live,
  getParameterMatch,
  setParameterMatch,
  uniqueTestcaseFields,
  appTestsetLinks,
  setAppTestsetLinks,
}) => {
  // | StringParameterType
  // | IntegerParameterType
  // | FloatParameterType
  // | MultiChoiceStringParameterType
  // | ConversationHistoryParameterType;

  const classifiedAndSortedParameters = useMemo(() => {
    return parameters
      .map((parameter) => {
        switch (parameter.param_type) {
          case "MULTICHOICESTRING":
            return {
              ...(parameter as MultiChoiceStringParameterType),
              classification: "MULTI-CHOICE",
            };
          case "INTEGER":
            return {
              ...(parameter as IntegerParameterType),
              classification:
                parameter.allowableMin !== null && parameter.allowableMax !== null
                  ? "CONSTRAINED_INTEGER"
                  : parameter.param_type,
            };
          case "FLOAT":
            return {
              ...(parameter as FloatParameterType),
              classification:
                parameter.allowableMin !== null && parameter.allowableMax !== null
                  ? "CONSTRAINED_FLOAT"
                  : parameter.param_type,
            };
          case "STRING":
            return {
              ...(parameter as StringParameterType),
              classification: parameter.name.toLowerCase().includes("system") ? "SYSTEM_MESSAGE" : "TEXT",
            };
          default:
            return {
              ...parameter,
              classification: parameter.param_type,
            };
        }
      })
      .sort((a, b) => UI_PARAMETER_ORDER.indexOf(a.classification) - UI_PARAMETER_ORDER.indexOf(b.classification));
  }, [parameters]);

  const allLinked =
    uniqueTestcaseFields != null &&
    classifiedAndSortedParameters
      .map((parameter) => getParameterMatch(parameter.name)?.source === VarSourceType.DATASET)
      .filter((x) => x === true).length === uniqueTestcaseFields.length;

  return (
    <div className="">
      <NameBlock name={name} docstring={docstring} last_active={last_active} is_live={is_live} />
      <div className="flex flex-row flex-wrap">
        {classifiedAndSortedParameters.map((parameter) =>
          parameter.classification === "MULTI-CHOICE" ? (
            <div key={parameter.name} className="w-1/2 px-4 space-y-2 mb-2">
              <TitleBlock
                advancedMode={advancedMode}
                parameter={parameter}
                uniqueTestcaseFields={uniqueTestcaseFields}
                getParameterMatch={getParameterMatch}
                setParameterMatch={setParameterMatch}
                allLinked={allLinked}
              />
              <select
                value={
                  getParameterMatch(parameter.name)?.source === VarSourceType.DATASET // If disabled, don't display anything
                    ? null
                    : getParameterMatch(parameter.name)?.value
                }
                onChange={(e) => setParameterMatch(parameter.name, VarSourceType.CONFIG, e.target.value)}
                className="select w-full bg-white"
                disabled={getParameterMatch(parameter.name)?.source === VarSourceType.DATASET}
              >
                {(parameter as MultiChoiceStringParameterType).choices?.map((option) => (
                  <option key={option}>{option}</option>
                ))}
              </select>
            </div>
          ) : parameter.classification === "CONSTRAINED_FLOAT" || parameter.classification === "CONSTRAINED_INTEGER" ? (
            <div key={parameter.name} className="w-1/2 px-4 mb-2 flex flex-col justify-between">
              <TitleBlock
                advancedMode={advancedMode}
                parameter={parameter}
                uniqueTestcaseFields={uniqueTestcaseFields}
                getParameterMatch={getParameterMatch}
                setParameterMatch={setParameterMatch}
                allLinked={allLinked}
              />
              <TemperatureSlider
                className="w-96"
                temperature={
                  getParameterMatch(parameter.name)?.source === VarSourceType.DATASET // If disabled, don't display anything
                    ? 1
                    : getParameterMatch(parameter.name)?.value
                }
                setTemperature={(newTemp) => setParameterMatch(parameter.name, VarSourceType.CONFIG, newTemp)}
                step={parameter.classification === "CONSTRAINED_FLOAT" ? 0.01 : 1}
                allowableMin={(parameter as FloatParameterType | IntegerParameterType).allowableMin as number} // typescript not picking up this has already been filtered for constrained params
                allowableMax={(parameter as FloatParameterType | IntegerParameterType).allowableMax as number} // typescript not picking up this has already been filtered for constrained params
                disabled={getParameterMatch(parameter.name)?.source === VarSourceType.DATASET}
              />
            </div>
          ) : parameter.classification === "FLOAT" || parameter.classification === "INTEGER" ? (
            <div key={parameter.name} className="w-1/2 px-4 space-y-2 mb-2">
              <TitleBlock
                advancedMode={advancedMode}
                parameter={parameter}
                uniqueTestcaseFields={uniqueTestcaseFields}
                getParameterMatch={getParameterMatch}
                setParameterMatch={setParameterMatch}
                allLinked={allLinked}
              />
              <input
                type="number"
                className="input bg-white"
                value={
                  getParameterMatch(parameter.name)?.source === VarSourceType.DATASET // If disabled, don't display anything
                    ? null
                    : getParameterMatch(parameter.name)?.value
                }
                onChange={(e) => setParameterMatch(parameter.name, VarSourceType.CONFIG, e.target.value)}
                disabled={getParameterMatch(parameter.name)?.source === VarSourceType.DATASET}
              />
            </div>
          ) : parameter.classification === "SYSTEM_MESSAGE" ? (
            <div key={parameter.name} className="w-full px-4 space-y-2 mb-2">
              <div className="w-full flex flex-row space-x-2 items-center">
                <TitleBlock
                  advancedMode={advancedMode}
                  parameter={parameter}
                  uniqueTestcaseFields={uniqueTestcaseFields}
                  getParameterMatch={getParameterMatch}
                  setParameterMatch={setParameterMatch}
                  allLinked={allLinked}
                />

                <AutoPromptIcon
                  insertCallback={(text) => {
                    setParameterMatch(parameter.name, VarSourceType.CONFIG, text);
                  }}
                  initialValue={getParameterMatch(parameter.name)?.value}
                />
              </div>
              <TextareaAutosize
                className="w-full textarea textarea-bordered bg-white"
                value={
                  getParameterMatch(parameter.name)?.source === VarSourceType.DATASET // If disabled, don't display anything
                    ? ""
                    : getParameterMatch(parameter.name)?.value
                }
                onChange={(e) => setParameterMatch(parameter.name, VarSourceType.CONFIG, e.target.value)}
                minRows={3}
                maxRows={8}
                disabled={getParameterMatch(parameter.name)?.source === VarSourceType.DATASET}
              />
            </div>
          ) : parameter.classification === "TEXT" ? (
            <div key={parameter.name} className="w-full px-4 space-y-2 mb-2">
              <TitleBlock
                advancedMode={advancedMode}
                parameter={parameter}
                uniqueTestcaseFields={uniqueTestcaseFields}
                getParameterMatch={getParameterMatch}
                setParameterMatch={setParameterMatch}
                allLinked={allLinked}
              />
              <TextareaAutosize
                className="w-full textarea textarea-bordered bg-white"
                value={
                  getParameterMatch(parameter.name)?.source === VarSourceType.DATASET // If disabled, don't display anything
                    ? ""
                    : getParameterMatch(parameter.name)?.value
                }
                onChange={(e) => setParameterMatch(parameter.name, VarSourceType.CONFIG, e.target.value)}
                minRows={3}
                maxRows={8}
                disabled={getParameterMatch(parameter.name)?.source === VarSourceType.DATASET}
              />
            </div>
          ) : parameter.classification === "CONVERSATIONHISTORY" ? (
            <div key={parameter.name} className="w-full px-4 space-y-2 mb-2">
              <TitleBlock
                advancedMode={advancedMode}
                parameter={parameter}
                uniqueTestcaseFields={uniqueTestcaseFields}
                getParameterMatch={getParameterMatch}
                setParameterMatch={setParameterMatch}
                allLinked={allLinked}
              />
              <ConversationHistory
                className="w-full"
                messages={
                  getParameterMatch(parameter.name)?.source === VarSourceType.DATASET // If disabled, don't display anything
                    ? []
                    : getParameterMatch(parameter.name)?.value
                }
                setMessages={(newValue) => setParameterMatch(parameter.name, VarSourceType.CONFIG, newValue)}
                disabled={getParameterMatch(parameter.name)?.source === VarSourceType.DATASET}
              />
            </div>
          ) : (
            <div key={parameter.name} className="w-1/2 h-10">
              Unrecognised Type For: {parameter.name} with classification {parameter.classification}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

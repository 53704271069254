import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ArrowLongUpIcon, BeakerIcon, Cog6ToothIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { AppPlayground } from "../../presentation_components/AppPlayground/AppPlayground";
import { AutoPromptButton } from "../../presentation_components/AutoPrompt";
import { PLAIN_LLM_CALL_API_KEY, DEMO_TESTSETS, CRITERIA_DETAILS, PLAYGROUND_DATACASE_HEIGHT } from "../../globals";
import { toast } from "react-toastify";
import { HistorySidebar } from "../../presentation_components/HistorySidebar";
import { PlaygroundToggle } from "../../presentation_components/PlaygroundToggle";
import { FloppyDiskIcon, HistoryIcon } from "../../presentation_components/primitives/Icons";
import { DropdownCase, DropdownDivider, DropdownOption } from "../../presentation_components/primitives/Dropdown";
import { TestcaseEditor } from "../../presentation_components/TestcaseEditor";
import { EditableTitleDisplay } from "../../presentation_components/EditableTitleDisplay";
import { useElementSize } from "../../hooks/useElementSize";
import { useModal } from "../../presentation_components/modals/useModal";
import Modal from "../../presentation_components/primitives/Modal";
import { App, Evalset, Evaluatorconfig, Testcase, Testset } from "../../utils/model";
import {
  LoadMethod,
  ValidLoadSpec,
  EvalsetLoadSpec,
  EvalcaseLoadSpec,
  CustomLoadSpec,
  DemoValuesLoadSpec,
} from "../../presentation_components/AppPlayground/controllers";
import { useEvaluatorconfigs } from "../../hooks/useEvaluatorconfigs";

type PlaygroundSpecType = {
  id: string;
  appLoadSpec: ValidLoadSpec | null;
  wantsToDisplayResults?: boolean;
};

// Used to generate random ids for playgrounds
const randomId = (): string =>
  Array.from({ length: 10 }, () => String.fromCharCode(Math.floor(Math.random() * 26) + 97)).join("");

function useTestsetChoices(userTestsets: Testset[] | null) {
  const testsetChoices = useMemo(() => {
    if (userTestsets == null) {
      return null;
    } else {
      let _testsetChoices: { section_name: string; testset_name: string; id: string | null }[] = [];

      // Add DEMO_APPS section
      structuredClone(DEMO_TESTSETS).forEach((testset) => {
        _testsetChoices.push({
          section_name: "DEMO_TESTSETS",
          testset_name: testset.name,
          id: null,
        });
      });

      // Add USER_APPS section
      userTestsets.forEach((testset) => {
        _testsetChoices.push({
          section_name: "USER_TESTSETS",
          testset_name: testset.name,
          id: testset.id,
        });
      });

      return _testsetChoices;
    }
  }, [userTestsets]);

  return testsetChoices;
}

// Playground page component
export default function Playground() {
  // Element height calculations
  const targetRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(targetRef);
  const adjustedHeight = size.height - 8 - 64; // This is to compensate for the h-16 header and the global padding
  const appHeight = Math.floor(adjustedHeight * (2 / 3));

  // PLAYGROUND STATE
  const [userTestsets, setUserTestsets] = useState<Testset[] | null>(null);
  const testsetChoices = useTestsetChoices(userTestsets);

  const [testcases, setTestcases] = useState<Testcase[] | null>(null);

  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [autoPromptOpen, setAutoPromptOpen] = useState<boolean>(false);

  // On initial load, set auto_prompt to true if it's set in the query parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  //   get autoPrompt from query params as boolean, if that fails, default to false
  const autoPrompt: boolean = queryParams.get("auto_prompt") === "true" || false;

  // get mode and fallback to app if not set
  enum LoadModeType {
    APP = "app",
    EVAL = "eval",
  }
  const loadMode: LoadModeType = queryParams.get("mode") === "eval" ? LoadModeType.EVAL : LoadModeType.APP;

  const loadParams: string[] = [queryParams.get("load1"), queryParams.get("load2"), queryParams.get("load3")].filter(
    (item): item is string => item !== null,
  );

  const [advancedMode, setAdvancedMode] = useState<boolean>(false);
  const [appPlaygrounds, setAppPlaygrounds] = useState<PlaygroundSpecType[]>([]);

  const registerResultsPreference = (playgroundId: string, hasResults: boolean) => {
    setAppPlaygrounds((prevPlaygrounds) => {
      const newPlaygrounds = [...prevPlaygrounds];
      const index = newPlaygrounds.findIndex((p) => p.id === playgroundId);
      if (index === -1) {
        return newPlaygrounds;
      } else {
        newPlaygrounds[index].wantsToDisplayResults = hasResults;
        return newPlaygrounds;
      }
    });
  };

  const [testcaseModalOpen, setTestcaseModalOpen] = useState<boolean>(false);
  const [testcaseModalEditingID, setTestcaseModalEditingID] = useState<string | null>(null);

  const [selectedTestset, setSelectedTestset] = useState<Testset | null>(null);
  const testsetFrozen = useMemo(() => selectedTestset == null || Testset.isFrozen(selectedTestset), [selectedTestset]);

  // true if any of the appPlaygrounds has wantsToDisplayResults set to true
  // also requires a testset, otherwise results summary is meaningless
  const anyPlaygroundWantsResults = appPlaygrounds.some((p) => p.wantsToDisplayResults) && selectedTestset != null;

  const defaultPlaygroundLoad: PlaygroundSpecType[] = [
    {
      id: randomId(),
      appLoadSpec: null,
    },
    {
      id: randomId(),
      appLoadSpec: null,
    },
  ];

  // TODO: this useEffect conflates the loadMode and the loadParams, loadParams is overloaded to refer to either app ids or evalset ids, which is a mess
  useEffect(() => {
    const populatePlaygrounds = async () => {
      if (loadMode === LoadModeType.APP) {
        if (loadParams.length === 0) {
          setAppPlaygrounds(defaultPlaygroundLoad);
        } else {
          const initialLoadSpecPromises: Promise<PlaygroundSpecType>[] = [];
          for (const load of loadParams) {
            if (load) {
              initialLoadSpecPromises.push(
                App.fetch(load).then((app) => {
                  return {
                    id: randomId(),
                    appLoadSpec: {
                      appId: app.id,
                      version: app.latest_version,
                      evalcaseId: null,
                      evalsetId: null,
                      customValues: null,
                      loadMethod: LoadMethod.APP_DEMO_VALUES,
                    } as DemoValuesLoadSpec,
                  };
                }),
              );
            }
          }

          const initialLoadSpecs: PlaygroundSpecType[] = await Promise.all(initialLoadSpecPromises);
          setAppPlaygrounds(initialLoadSpecs);
        }
        setAdvancedMode(false);
      } else if (loadMode === LoadModeType.EVAL) {
        if (loadParams.length === 0) {
          // Requested an eval load but no evalset was provided
          setAppPlaygrounds(defaultPlaygroundLoad); // You could consider loading in a default testset too
        } else {
          Promise.all(
            loadParams.map((load) => {
              return Evalset.fetch(load).then((evalset) => {
                return {
                  id: randomId(),
                  appLoadSpec: {
                    appId: evalset.app_id,
                    version: evalset.version,
                    loadMethod: LoadMethod.EVALSET,
                    evalsetId: evalset.id,
                    evalcaseId: null,
                    customValues: null,
                  } as EvalsetLoadSpec,
                };
              });
            }),
          ).then((specs) => {
            setAppPlaygrounds(specs);
          });
        }

        setAdvancedMode(true);
      }
    };

    populatePlaygrounds();
  }, [loadMode]);

  // fetch all testsets on page load
  useEffect(() => {
    Testset.fetchAll().then((x) => {
      setUserTestsets(x);
    });
  }, []);

  // fetch testcases when possible
  useEffect(() => {
    if (selectedTestset != null) {
      Testset.fetchTestcases(selectedTestset).then((x) => {
        setTestcases(x);
      });
    } else {
      clearTests();
    }
  }, [selectedTestset]);

  useEffect(() => {
    if (autoPrompt) {
      setAutoPromptOpen(true);
    }
  }, [autoPrompt]);

  useEffect(() => {
    clearTests();
  }, [advancedMode]);

  const { evaluatorconfigsByTestcaseId, setEvaluatorconfigs } = useEvaluatorconfigs(testcases);

  const addPlayground = () => {
    if (appPlaygrounds.length < 2) {
      setAppPlaygrounds([...appPlaygrounds, { id: randomId(), appLoadSpec: null }]);
    } else {
      toast.error("There is no space for a new playground.");
    }
  };

  const pushToPlayground = (loadSpec: ValidLoadSpec, playgroundId: string) => {
    setAppPlaygrounds((appPlaygrounds) => {
      const newAppPlaygrounds = [...appPlaygrounds];
      const index = newAppPlaygrounds.findIndex((p) => p.id === playgroundId);
      newAppPlaygrounds[index].appLoadSpec = loadSpec;
      return newAppPlaygrounds;
    });
  };

  const deleteMe = (playgroundId: string) => {
    setAppPlaygrounds((prevPlaygrounds) => prevPlaygrounds.filter((p) => p.id !== playgroundId));
  };

  const clearTests = () => {
    setTestcases(null);
    setSelectedTestset(null);
  };

  if (userTestsets == null || testsetChoices == null) {
    return (
      <div
        className="platform-container relative flex flex-col px-2 py-0 max-h-full h-full overflow-hidden"
        ref={targetRef}
      ></div>
    );
  }

  const closeTestcaseModal = () => {
    setTestcaseModalEditingID(null);
    setTestcaseModalOpen(false);
  };

  return (
    // platform-container sets min-f-full but we need h-full so it is explicitly defined for children
    <div
      className="platform-container relative flex flex-col px-2 py-0 max-h-full h-full overflow-hidden"
      ref={targetRef}
    >
      <Modal isOpen={testcaseModalOpen} closeModal={closeTestcaseModal}>
        <div className="w-5/6 h-5/6 flex flex-row transparent relative">
          <div className="w-full h-full bg-white rounded-lg p-16 flex flex-col shadow-xl">
            {testcaseModalEditingID != null && (
              <TestcaseEditor
                testcase={testcases != null ? testcases.find((x) => x.id === testcaseModalEditingID) || null : null}
                setTestcases={setTestcases}
                testcases={testcases}
                evaluatorconfigs={evaluatorconfigsByTestcaseId[testcaseModalEditingID]}
                setEvaluatorconfigs={(newEvaluatorconfigs: Evaluatorconfig[]) =>
                  setEvaluatorconfigs(testcaseModalEditingID, newEvaluatorconfigs)
                }
                frozen={testsetFrozen}
                launchAsModal={() => {}}
                closeTestcaseModal={closeTestcaseModal}
              />
            )}
          </div>
        </div>
      </Modal>
      <div
        className={`flex flex-col grow ${
          advancedMode ? "min-h-full overflow-y-auto no-scrollbar" : "h-full overflow-hidden"
        }`}
      >
        {/* HISTORY FULL HEIGHT COMES OUT RIGHT */}
        <HistorySidebar
          isShowing={showHistory}
          setIsShowing={setShowHistory}
          pushToPlayground={pushToPlayground}
          appPlaygrounds={appPlaygrounds}
          displayEvalcaseHistory={!advancedMode}
        />

        {/* HEADER */}
        <div
          className="flex flex-row items-center justify-between px-4"
          style={{
            minHeight: 64,
            maxHeight: 64,
          }}
        >
          <div className="flex flex-row space-x-4 items-center">
            <PlaygroundToggle advancedMode={advancedMode} setAdvancedMode={setAdvancedMode} />
          </div>

          {/* Right button cluster */}
          <div className="space-x-2">
            <AutoPromptButton
              isOpen={autoPromptOpen}
              setIsOpen={setAutoPromptOpen}
              insertCallback={(text: string) => {
                if (appPlaygrounds.length === 2) {
                  toast.error("There is no space for a new playground.");
                } else {
                  const newAppPlaygrounds = [
                    ...appPlaygrounds,
                    {
                      id: randomId(),
                      appLoadSpec: null,
                    },
                  ];
                  setAppPlaygrounds(newAppPlaygrounds);
                }
              }}
            />
            <HistoryIcon onClick={() => setShowHistory(!showHistory)} className="simple-icon-button" />
            <PlusIcon onClick={addPlayground} className="simple-icon-button stroke-2 " />
          </div>
        </div>

        {/* APPS */}
        <div className="grow w-full flex flex-row">
          {/* need h-0 otherwise it forces the parent div larger */}
          <div
            className={`transition-all duration-700`}
            style={{
              height: advancedMode ? "" : adjustedHeight,
              width: advancedMode ? size.width / 3 : 0,
              marginRight: advancedMode ? 16 : 0,
              opacity: advancedMode ? 1 : 0,
            }}
          >
            <div
              className="p-2 flex flex-col space-y-4 items-center justify-end "
              style={{ height: appHeight, width: size.width / 3 }}
              // style={{ height: appHeight }}
            >
              <DropdownCase label={"Select a Testset"} className="w-72 btn-primary btn-sm">
                <DropdownOption selected={false} onClick={clearTests}>
                  Clear Tests
                </DropdownOption>
                <DropdownDivider label="Your test sets" />
                {testsetChoices
                  .filter((x) => x.section_name === "USER_TESTSETS")
                  .map((testset, index) => (
                    <DropdownOption
                      key={index}
                      onClick={() => {
                        const fromUserTestsets = userTestsets.find((x) => x.id === testset.id);
                        setSelectedTestset(fromUserTestsets ? fromUserTestsets : null);
                      }}
                      selected={selectedTestset?.id === testset.id}
                    >
                      {testset.testset_name}
                    </DropdownOption>
                  ))}
                <DropdownDivider label="Demo test sets" />
                {testsetChoices
                  .filter((x) => x.section_name === "DEMO_TESTSETS")
                  .map((testsetChoice, index) => (
                    <DropdownOption
                      key={"demos" + index}
                      onClick={async () => {
                        toast.info("Creating test set...");

                        // const demoTestset = DEMO_TESTSETS.filter((x) => x.name === testsetChoice.testset_name)[0];

                        await Testset.createDemoTestset(testsetChoice.testset_name).then((newTestset) => {
                          Testset.fetchTestsetAndTestcases(newTestset.id).then(([newTestset, newTestcases]) => {
                            setUserTestsets([...userTestsets, newTestset]);
                            setSelectedTestset(newTestset);
                          });
                        });
                      }}
                      selected={false} // items in the demo section can never be selected
                    >
                      {testsetChoice.testset_name}
                    </DropdownOption>
                  ))}
              </DropdownCase>

              {/* Testset Options */}
              <div className="h-12 w-full flex flex-row justify-center">
                {selectedTestset && (
                  <div className="h-full flex flex-row items-center space-x-4">
                    <EditableTitleDisplay
                      text={selectedTestset?.name}
                      rename={(newName: string) => {
                        Testset.update(selectedTestset.id, { name: newName }).then((updatedTestset) => {
                          setUserTestsets(
                            userTestsets.map((x) => {
                              if (x.id === updatedTestset.id) {
                                return updatedTestset;
                              } else {
                                return x;
                              }
                            }),
                          );
                        });
                      }}
                      inputClassName="w-64"
                      displayClassName="text-maindarkgray text-sm font-semibold capitalize" // this is a copy paste of the h4 styles
                    />
                    <TrashIcon
                      className="simple-icon-button text-red-700"
                      onClick={async () => {
                        toast.info("Deleting test set...");
                        await Testset.delete(selectedTestset.id)
                          .then((x) => setUserTestsets(userTestsets.filter((y) => y.id !== selectedTestset.id)))
                          .then((_) => {
                            clearTests();
                            toast.success("Successfully deleted test set.");
                          });
                      }}
                    />
                    <div className="relative"></div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="h-10 flex flex-row space-x-2 text-sm font-semibold bg-mainbordergray mt-10 text-white rounded-md shadow"
              style={{ width: size.width / 3 }}
            >
              <div className="w-2/3 h-full flex flex-row items-center px-2 justify-start space-x-2">
                <h3 className="text-white uppercase">Test Cases</h3>
                <div className="tooltip flex flex-row items-center justify-center" data-tip="Add a new test case">
                  <button
                    className="simple-icon-button"
                    onClick={() => {
                      if (selectedTestset == null || testcases == null) {
                        toast.error("Please select a testset first.");
                        return;
                      } else if (testsetFrozen) {
                        toast.error("This testset is frozen and cannot be modified.");
                        return;
                      }

                      Testset.addBlankTestcase(selectedTestset, testcases).then(
                        ([newTestset, newTestcase]: [Testset, Testcase]) => {
                          // Append the new testcase to the list of testcases
                          setTestcases([newTestcase, ...testcases]);
                          setUserTestsets(
                            userTestsets.map((x) => {
                              if (x.id === newTestset.id) {
                                return newTestset;
                              } else {
                                return x;
                              }
                            }),
                          );
                        },
                      );
                    }}
                  >
                    <PlusIcon />
                  </button>
                </div>
              </div>
              <div className="w-1/3 h-full flex flex-row items-center px-2 justify-end">
                <h3 className="text-white uppercase">Evaluators</h3>
              </div>
            </div>
            <div
              className="w-full"
              style={{
                height: anyPlaygroundWantsResults ? "288px" : "0px",
              }}
            ></div>
            <div className="flex flex-col mt-4 space-y-4" style={{ width: size.width / 3 }}>
              {testcases !== null ? (
                testcases?.map((testcase, index) => (
                  <TestcaseEditor
                    testcase={testcase}
                    testcases={testcases}
                    setTestcases={setTestcases}
                    evaluatorconfigs={evaluatorconfigsByTestcaseId[testcase.id]}
                    setEvaluatorconfigs={(newEvaluatorconfigs: Evaluatorconfig[]) =>
                      setEvaluatorconfigs(testcase.id, newEvaluatorconfigs)
                    }
                    launchAsModal={
                      () => {
                        setTestcaseModalOpen(true); //
                        setTestcaseModalEditingID(testcase.id);
                      }
                      // modalProvider.openModal("TESTCASE_EDITOR", { testcase, testcases, setTestcases, testsetFrozen })
                    }
                    frozen={testsetFrozen}
                    style={{
                      height: PLAYGROUND_DATACASE_HEIGHT,
                    }}
                    key={index}
                  />
                ))
              ) : (
                <div className="w-full h-48 flex flex-row justify-center items-center space-x-8 pr-16 text-maindarkgray">
                  <ArrowLongUpIcon className="h-10 w-10 " />
                  <div>Select a testset</div>
                </div>
              )}
            </div>
          </div>

          <div
            className={`transition-all duration-700 flex flex-row grow z-10 ${
              advancedMode ? "space-x-4" : "space-x-2"
            }`}
            style={{
              width: "100%", // although this implies width will be 100% in advanced mode, it actually just takes up the remaining space
            }}
          >
            {appPlaygrounds &&
              appPlaygrounds.map((appPlayground, index) => (
                <AppPlayground
                  advancedMode={advancedMode}
                  testset={selectedTestset}
                  setTestset={setSelectedTestset}
                  testcases={testcases}
                  coreHeight={advancedMode ? appHeight : adjustedHeight}
                  deleteMe={() => deleteMe(appPlayground.id)}
                  externalAppLoadSpec={appPlayground.appLoadSpec}
                  anyPlaygroundWantsResults={anyPlaygroundWantsResults}
                  enableResults={() => registerResultsPreference(appPlayground.id, true)}
                  disableResults={() => registerResultsPreference(appPlayground.id, false)}
                  key={appPlayground.id}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

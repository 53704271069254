import React, { useEffect, useState } from "react";
import Modal from "./primitives/Modal";
import { SparklesIcon, Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DropdownCase, DropdownOption } from "./primitives/Dropdown";
import { Menu } from "@headlessui/react";
import { makeApiRequest, makeApiRequestPromise } from "../utils/ApiRequest";
import { RainbowWandIcon } from "./primitives/Icons";

const targetLanguageMap: { [key: string]: string } = {
  en: "English",
  fr: "French",
  de: "German",
  es: "Spanish",
  it: "Italian",
  pt: "Portuguese",
  ru: "Russian",
  zh: "Chinese",
  ja: "Japanese",
};

interface OptimiseButtonCollectionProps {
  disabled: boolean;
  onClick: () => void;
  options: AutoPromptOptions;
  setOptions: (options: AutoPromptOptions) => void;
}

const OptimiseButtonCollection: React.FC<OptimiseButtonCollectionProps> = ({
  disabled,
  onClick,
  options,
  setOptions,
}) => {
  return (
    <div className="h-12 space-x-4 flex flex-row items-center justify-center ">
      <div className="dropdown dropdown-right flex flex-col justify-center">
        <Menu>
          <Menu.Button as="label" tabIndex={0}>
            <button disabled={disabled}>
              <Cog6ToothIcon className="simple-icon-button" />
            </button>
          </Menu.Button>

          <Menu.Items
            as="ul"
            className="shadow menu dropdown-content z-[1] bg-mainlightgray rounded-box w-72 p-4 space-y-4"
          >
            <div className="flex flex-row justify-between">
              <div className="font-semibold">Advanced Options</div>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <div>Target language</div>
              <select className="select select-bordered select-sm max-w-xs">
                {Object.keys(targetLanguageMap).map((language, index) => (
                  <option selected={options.targetLanguage === language} key={index}>
                    {targetLanguageMap[language]}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <div className={`${options.shortenPrompt ? "text-black" : "text-maindarkgray"}`}>Shorten Prompt</div>
              <input
                type="checkbox"
                className="toggle"
                checked={options.shortenPrompt}
                onChange={() =>
                  setOptions({
                    ...options,
                    shortenPrompt: !options.shortenPrompt,
                  })
                }
              />
            </div>
          </Menu.Items>
        </Menu>
      </div>

      <button disabled={disabled} className="btn btn-primary w-48 text-white fill-white" onClick={onClick}>
        Optimize
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 131.25" className="w-5 h-5 fill-white">
          <path d="M46.92,39.23l-8.47,8.5a2.16,2.16,0,0,1-3,0L18.67,31a2.68,2.68,0,0,1,0-3.78l7.75-7.74a2.66,2.66,0,0,1,3.78,0L46.92,36.2A2.14,2.14,0,0,1,46.92,39.23Zm6.68,3.65a2.14,2.14,0,0,0-3,0l-8.48,8.5a2.15,2.15,0,0,0,0,3L88.57,100.9a4.3,4.3,0,0,0,6.08,0l5.45-5.45a4.3,4.3,0,0,0,0-6.08ZM73.48,19.13A8.16,8.16,0,0,1,67,12.66l-1.35-7a1.07,1.07,0,0,0-2.11,0l-1.35,7a8.15,8.15,0,0,1-6.47,6.47l-7,1.35a1.07,1.07,0,0,0,0,2.11l7,1.35a8.17,8.17,0,0,1,6.47,6.47l1.35,7a1.07,1.07,0,0,0,2.11,0l1.35-7a8.19,8.19,0,0,1,6.47-6.47l7-1.35a1.07,1.07,0,0,0,0-2.11Zm-61.26.14.62,3.21a1.07,1.07,0,0,0,2.11,0l.62-3.21a5.69,5.69,0,0,1,4.51-4.5l3.2-.62a1.07,1.07,0,0,0,0-2.11l-3.2-.62a5.69,5.69,0,0,1-4.51-4.5L15,3.71a1.07,1.07,0,0,0-2.11,0l-.62,3.21a5.68,5.68,0,0,1-4.5,4.5L4.51,12a1.07,1.07,0,0,0,0,2.11l3.21.62A5.68,5.68,0,0,1,12.22,19.27Zm7.27,36.08a3.85,3.85,0,0,1,3.05,3l.38,2a1.07,1.07,0,0,0,2.11,0l.37-2a3.87,3.87,0,0,1,3.05-3L30.39,55a1.07,1.07,0,0,0,0-2.11l-1.94-.38a3.85,3.85,0,0,1-3.05-3l-.37-2a1.07,1.07,0,0,0-2.11,0l-.38,2a3.84,3.84,0,0,1-3.05,3l-1.94.38a1.07,1.07,0,0,0,0,2.11Z" />
        </svg>
      </button>
    </div>
  );
};

interface AutoPromptIconProps {
  insertCallback: (prompt: string) => void;
  initialValue: string | null;
}

export const AutoPromptIcon: React.FC<AutoPromptIconProps> = ({ insertCallback, initialValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="tooltip" data-tip="Auto Optimise">
        <RainbowWandIcon className="simple-icon-button w-6 h-6" onClick={() => setIsOpen(true)} />
      </div>
      <AutoPrompt
        insertCallback={insertCallback}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        insertLabel="Insert Prompt"
        externalInitialValue={initialValue}
      />
    </>
  );
};

interface AutoPromptButtonProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  insertCallback: (prompt: string) => void;
}

export const AutoPromptButton: React.FC<AutoPromptButtonProps> = ({ isOpen, setIsOpen, insertCallback }) => {
  return (
    <>
      <div className="rounded-full btn btn-sm btn-primary text-xs capitalize" onClick={() => setIsOpen(true)}>
        AI Prompt Writer
      </div>
      <AutoPrompt
        insertCallback={insertCallback}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        insertLabel="Launch in Playground"
      />
    </>
  );
};

interface AutoPromptOptions {
  prompt: string | null;
  targetModel: string;
  shortenPrompt: boolean;
  targetLanguage: string;
}

interface AutoPromptProps {
  insertCallback: (prompt: string) => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  insertLabel?: string;
  externalInitialValue?: string | null;
}

export const AutoPrompt: React.FC<AutoPromptProps> = ({
  insertCallback,
  isOpen,
  setIsOpen,
  insertLabel = "launch",
  externalInitialValue = null,
}) => {
  const [inputPrompt, setInputPrompt] = useState("");

  const [loading, setLoading] = useState(false);
  const [transitioned, setTransitioned] = useState(false);
  const [options, setOptions] = useState<AutoPromptOptions>({
    prompt: null,
    targetModel: "chatgpt",
    shortenPrompt: false,
    targetLanguage: "en",
  });
  const [optimizedPrompt, setOptimizedPrompt] = useState("");

  // set inputPrompt to externalInitialValue if it exists, but only do this on the first render
  useEffect(() => {
    if (externalInitialValue !== null) {
      setInputPrompt(externalInitialValue);
    }
  }, [externalInitialValue]);

  const makeOptimizeRequest = async () => {
    setLoading(true);
    setTransitioned(true);

    try {
      const response = await makeApiRequestPromise({
        path: "/api/optimize",
        method: "POST",
        body: {
          prompt: inputPrompt,
          targetModel: options.targetModel,
          shortenPrompt: options.shortenPrompt,
          targetLanguage: options.targetLanguage,
        },
      });
      setLoading(false);
      setOptimizedPrompt(response.result.promptOptimized);
    } catch (error) {
      setLoading(false);
    }
  };

  const allowedModels = [
    "chatgpt",
    "gpt-4-turbo",
    "claude-2",
    "gpt-4",
    "claude",
    "llama-2-70b-chat",
    "mistral-7b-instruct",
    "llama-2-13b-chat",
    "llama-2-7b-chat",
    "stablelm-tuned-alpha-7b",
    "text-davinci-003",
    "dalle-3",
    "midjourney",
    "dalle",
  ];

  return (
    <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
      <div className="w-2/3 h-5/6 flex flex-row transparent relative">
        <div className="w-1/2 h-full bg-white rounded-l-lg p-16 flex flex-col shadow-xl">
          <div className="text-lg font-bold flex flex-row items-center mb-8 h-8 ">
            <SparklesIcon className="w-5 h-5 mr-4 text-primary" /> AI Prompt Writer
          </div>
          <div className="grow flex flex-col items-start justify-start space-y-4">
            <div className="w-full flex flex-row justify-between items-center min-h-12 ">
              <div className="text-md font-bold">Prompt</div>
              <div className="flex flex-row items-center justify-center space-x-4">
                <DropdownCase label={options.targetModel} className="w-48 btn-sm font-normal">
                  <div key={"name"}>
                    <div className="px-3 pt-1 font-semibold">Select Target Model</div>
                    <div className="divider my-1"></div>
                  </div>
                  {allowedModels.map((model) => (
                    <DropdownOption
                      key={model}
                      onClick={() => setOptions({ ...options, targetModel: model })}
                      selected={options.targetModel === model}
                      className="btn-sm"
                    >
                      {model}
                    </DropdownOption>
                  ))}
                </DropdownCase>
              </div>
            </div>
            <textarea
              className="textarea textarea-primary resize-none h-full w-full rounded-lg p-2"
              value={inputPrompt}
              onChange={(e) => setInputPrompt(e.target.value)}
              placeholder="Enter your prompt here..."
            ></textarea>
          </div>
          <div
            className={`${
              transitioned ? "visible" : "hidden"
            } h-18 mt-8 flex flex-row justify-end transition-all duration-700`}
          >
            <OptimiseButtonCollection
              disabled={loading}
              onClick={makeOptimizeRequest}
              options={options}
              setOptions={setOptions}
            />
          </div>
        </div>

        <div className="w-1/2 h-full bg-white rounded-r-lg p-8 flex flex-col shadow-xl">
          <div className="w-full flex flex-row justify-end h-16">
            <XMarkIcon className="simple-icon-button" onClick={() => setIsOpen(false)} />
          </div>

          <div className="h-full w-full flex flex-col pl-8 pr-8 pb-8">
            {transitioned ? (
              loading ? (
                <div className="w-full h-full flex flex-row items-center justify-center">
                  <span className="loading loading-spinner loading-lg"></span>
                </div>
              ) : (
                <div className="w-full grow flex flex-col">
                  <div className="text-md font-bold mb-4 mt-8  min-h-12 flex flex-col justify-center">
                    <div>Optimized Prompt</div>
                  </div>
                  <div className="textarea textarea-primary h-full w-full rounded-lg p-2">{optimizedPrompt}</div>
                  <div className="h-18 mt-8 flex flex-row justify-end">
                    <button
                      className="btn btn-primary w-48 text-white fill-white"
                      onClick={() => {
                        insertCallback(optimizedPrompt);
                        setIsOpen(false);
                      }}
                    >
                      {insertLabel}
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="w-full grow flex flex-row justify-center items-center">
                <OptimiseButtonCollection
                  disabled={loading}
                  onClick={makeOptimizeRequest}
                  options={options}
                  setOptions={setOptions}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

import urlJoin from "url-join";
import { TenantType } from "./interfaces";
import { Tenant } from "./model";

export const getTenantId = (): string | null => {
  const urlArray = window.location.pathname.split("/").filter((n) => n); // url as array

  if (urlArray.length > 0) {
    // if the first part of the URL contains a t- prefix, we assume it's a tenant
    if (urlArray[0].startsWith("t-")) {
      const tenantId = urlArray[0].replace("t-", "");
      return tenantId;
    }
  }
  return null;
};

export const tenantedRouteBuilder = (route: string, tenantId?: string | null) => {
  if (!tenantId) {
    tenantId = getTenantId();
  }

  // do nothing for a null or undefined route
  if (tenantId) {
    return urlJoin(`/t-${tenantId}`, route);
  } else {
    return route;
  }
};

export const getTenant = async (): Promise<TenantType | null> => {
  const tenantId = getTenantId();
  if (tenantId) {
    return Tenant.fetch(tenantId)
      .then((tenant) => {
        if (tenant) {
          return tenant;
        } else {
          // tenant is not valid, reload the page but use a URL with the tenant stripped out
          window.location.href = "/";
          return null;
        }
      })
      .catch((e) => {
        // tenant is not valid, reload the page but use a URL with the tenant stripped out
        window.location.href = "/";
        return null;
      });
  }
  return null;
};

// export const setTenant = (newTenant: TenantType): void => {
//   // reload the page with the tenant ID inserted
//   if (!window.location.pathname.includes("/t-")) {
//     window.location.href = `/t-${newTenant.id}${window.location.pathname}`;
//   }
// };

export const setTenant = (newTenant: TenantType): void => {
  window.location.href = `/t-${newTenant.id}`;
};

export const initializeTenant = async (): Promise<void> => {
  const tenants = await Tenant.fetchAll();
  const personalTenants = tenants.filter((t) => t.personal);
  if (personalTenants.length > 0) {
    setTenant(personalTenants[0]);
  } else {
    const createdTenant = await Tenant.create({ name: "Private", personal: true });
    setTenant(createdTenant);
  }
};

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import React, { createContext, useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator, useTheme, View, Theme } from "@aws-amplify/ui-react";
import ProfileSettings from "./container_components/pages/ProfileSettings";
import Sidebar from "./presentation_components/PlatformSidebar";

import ProjectsPage from "./container_components/pages/Project";
import Playground from "./container_components/pages/Playground";
import Evaluations from "./container_components/pages/Evaluations";
import TestsetEditor from "./container_components/pages/TestsetDetails";
import Testsets from "./container_components/pages/Testset";
import { ScreenSizeCheck } from "./presentation_components/ScreenSizeCheck";
import AppDetails from "./container_components/pages/AppDetails";
import { ProtectedRoute } from "./presentation_components/primitives/ProtectedRoute";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { AUTH_PATH } from "./globals";
import { ModalProvider } from "./presentation_components/modals/useModal";
import urlJoin from "url-join";
import CompanyEmailValidator from "company-email-validator";
import AppCreationPage from "./container_components/pages/AppRegistration";
import { TenantProvider } from "./utils/TenantContext";
import { getTenantId, tenantedRouteBuilder } from "./utils/tenantManager";

// CONFIGURATION FOR AMPLIFY AUTH PAGE
/// https://ui.docs.amplify.aws/react/theming#theme-object
/// Allowed fields here https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#updating-labels-placeholders-required-fields-and-showing-labels
const formFields = {
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
  signUp: {
    username: {
      label: "Work Email",
      placeholder: "Enter your work email",
    },
  },
  resetPassword: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img
          src={urlJoin(import.meta.env.BASE_URL, "/logo.svg")}
          className="w-full h-14 mb-8 mt-10"
          alt="Composo logo"
        />
      </View>
    );
  },
};

const amplifyTheme: Theme = {
  name: "Custom Theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: "#AFD0CD" },
          80: { value: "#45918A" },
          90: { value: "#2B817A" },
          100: { value: "#107169" },
        },
      },
    },
  },
};

const services = {
  async handleSignUp(formData: any) {
    let { username, password, attributes } = formData;
    // custom username
    username = username.toLowerCase();

    const isCompanyEmail = CompanyEmailValidator.isCompanyEmail(username);

    if (isCompanyEmail) {
      return Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      });
    } else {
      throw new Error("Please sign up with your work email."); // Throws a new Error object
    }
  },
};

const AppRouter: React.FC = () => {
  const tenantPrefix: string = tenantedRouteBuilder("") ? tenantedRouteBuilder("") : "";

  return (
    <Authenticator.Provider>
      <Router>
        <ModalProvider>
          <Switch>
            {/* NON TENANTED PLATFORM ROUTES */}
            <Route exact path={AUTH_PATH}>
              <ThemeProvider theme={amplifyTheme}>
                <Authenticator services={services} formFields={formFields} components={components} hideSignUp={true}>
                  <Redirect to="/" />
                </Authenticator>
              </ThemeProvider>
            </Route>
            <ProtectedRoute exact path="/profilesettings" component={ProfileSettings} />
            <ProtectedRoute path="/link" component={AppCreationPage} />

            {/* TENANTED PLATFORM ROUTES */}
            {/* IF THE USER DOESN'T HIT ANY OF THE NON TENANTED ROUTES, THEY'LL BE TENANTED BY THE TENANT PROVIDER HERE */}
            <TenantProvider>
              <Route path={tenantPrefix}>
                <ScreenSizeCheck>
                  <div
                    id="platformcontainer"
                    className="h-screen w-screen bg-white flex overflow-hidden"
                    style={{
                      minHeight: "350px",
                      minWidth: "700px",
                    }}
                  >
                    <Sidebar />
                    <div className="flex-grow overflow-auto">
                      <Switch>
                        <Route exact path={urlJoin(tenantPrefix, "/playground")} render={() => <Playground />} />

                        <ProtectedRoute exact path={urlJoin(tenantPrefix, "project")} component={ProjectsPage} />

                        <ProtectedRoute exact path={urlJoin(tenantPrefix, "project/:id")} component={AppDetails} />

                        <ProtectedRoute exact path={urlJoin(tenantPrefix, "evaluation")} component={Evaluations} />

                        <ProtectedRoute exact path={urlJoin(tenantPrefix, "testset")} component={Testsets} />

                        <ProtectedRoute exact path={urlJoin(tenantPrefix, "testset/:id")} component={TestsetEditor} />

                        <Redirect exact from={tenantPrefix} to={urlJoin(tenantPrefix, "/project")} />

                        <Route>
                          <div>
                            <h1>404 - Not Found</h1>
                          </div>
                        </Route>

                        {/* Add more platform routes here */}
                      </Switch>
                    </div>
                  </div>
                </ScreenSizeCheck>
              </Route>
            </TenantProvider>
          </Switch>
        </ModalProvider>
      </Router>
    </Authenticator.Provider>
  );
};

export default AppRouter;

type DBRecordType = {
  id: string;
  created: string;
};

type ArchiveableDBRecordType = DBRecordType & {
  deleted_at: string | null;
  snapshotted_at: string | null;
  snapshot_source: string | null;
};

type TenantedDBRecordType = ArchiveableDBRecordType & {
  tenant_id: string;
};

export enum CriteriaSelectorEnum {
  NO_CRITERIA = "NO_CRITERIA",
  VECTOR_SIMILARITY = "VECTOR_SIMILARITY",
  EXACT_MATCH = "EXACT_MATCH",
  JSON = "JSON",
  AI_GRADED_SIMILARITY = "AI_GRADED_SIMILARITY",
  AI_GRADED_CUSTOM = "AI_GRADED_CUSTOM",
  AI_GRADED_FORMULA = "AI_GRADED_FORMULA",
  RAG_CONTEXT_FAITHFULNESS = "RAG_CONTEXT_FAITHFULNESS",
  RAG_ANSWER_RELEVANCE = "RAG_ANSWER_RELEVANCE",
  RAG_CONTEXT_RECALL = "RAG_CONTEXT_RECALL",
  RAG_CONTEXT_PRECISION = "RAG_CONTEXT_PRECISION",
}

export enum VarSourceType {
  DATASET = "DATASET",
  CONFIG = "CONFIG",
  DEMO = "DEMO",
}

export type PipelineStageType =
  | "LOAD_FROM_JSON_STRING"
  | "SINGLE_INDEX"
  | "DICT_DRILL_DOWN"
  | "STRING_SPLIT"
  | "STRING_JOIN";

export interface PipelineStage {
  COMMAND: PipelineStageType;
  PARAMS: Record<string, any>;
}

export interface PipelineSpec {
  STAGES: PipelineStage[];
}

// User
export type UserType = ArchiveableDBRecordType & {
  first_name: string | null;
  email: string | null;
  fingerprint: string | null;
  cognito_username: string | null;
};

export type UserUpdateType = {
  first_name: string;
};

// Tenant
export type TenantCreateType = {
  name: string;
  personal: boolean;
};

export type TenantUpdateType = {
  name?: string;
  personal?: boolean;
};

export type TenantType = ArchiveableDBRecordType & TenantCreateType;

// Assignments
export type AssignmentCreateType = ArchiveableDBRecordType & {
  user_id: string;
  tenant_id: string;
};

export type EvaluatorConfigJoinsAppCreateType = {
  evaluatorconfig_id: string;
  app_id: string;
};

export type EvaluatorConfigJoinsAppReturnType = TenantedDBRecordType & EvaluatorConfigJoinsAppCreateType;

export type EvaluatorConfigJoinsDatasetCreateType = {
  evaluatorconfig_id: string;
  dataset_id: string;
};

export type EvaluatorConfigJoinsDatasetReturnType = TenantedDBRecordType & EvaluatorConfigJoinsDatasetCreateType;

export type EvaluatorConfigJoinsDatacaseCreateType = {
  evaluatorconfig_id: string;
  datacase_id: string;
};

export type EvaluatorConfigJoinsDatacaseReturnType = TenantedDBRecordType & EvaluatorConfigJoinsDatacaseCreateType;

// App
export enum ParameterType {
  STRING = "STRING",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
  MULTICHOICESTRING = "MULTICHOICESTRING",
  CONVERSATIONHISTORY = "CONVERSATIONHISTORY",
}

export interface ParameterBaseType {
  param_type: ParameterType;
  name: string;
  is_kwarg: boolean;
  description: string | null;
}

export interface StringParameterType extends ParameterBaseType {
  param_type: ParameterType.STRING;
  demo_value: string;
  kwarg_value: string | null;
}

export interface IntegerParameterType extends ParameterBaseType {
  param_type: ParameterType.INTEGER;
  demo_value: number;
  kwarg_value: number | null;
  allowableMin: number | null;
  allowableMax: number | null;
}

export interface FloatParameterType extends ParameterBaseType {
  param_type: ParameterType.FLOAT;
  demo_value: number;
  kwarg_value: number | null;
  allowableMin: number | null;
  allowableMax: number | null;
}

export interface MultiChoiceStringParameterType extends ParameterBaseType {
  param_type: ParameterType.MULTICHOICESTRING;
  demo_value: string;
  kwarg_value: string | null;
  choices: string[] | null;
}

export interface ConversationHistoryParameterType extends ParameterBaseType {
  param_type: ParameterType.CONVERSATIONHISTORY;
  demo_value: any[];
  kwarg_value: any[] | null;
}

export type ParameterTypes =
  | StringParameterType
  | IntegerParameterType
  | FloatParameterType
  | MultiChoiceStringParameterType
  | ConversationHistoryParameterType;

export type AppCreateType = {
  name: string;
  api_key: string;
};

export type AppUpdateType = {
  name?: string;
};

export type AppReturnType = TenantedDBRecordType & {
  name: string;

  // calculated
  api_key: string;
  last_active: string;
  latest_version: string;
  is_live: boolean;
};

// Runner
export type RunnerCreateType = {
  api_key: string;
  auto_bump?: boolean;

  parameters: ParameterTypes[];
  runner_type: string;
  package_version: string;
  docstring?: string | null;
};

export type RunnerReturnType = DBRecordType & {
  // calculated on creation
  runnerfamily_id: string;
  last_active: string;
  is_live: boolean;
  version: string;

  parameters: ParameterTypes[];
  runner_type: string;
  package_version: string;
  docstring?: string | null;
};

// Testset
export type TestsetCreateType = {
  name: string;
};

export type TestsetUpdateType = TestsetCreateType;

export type TestsetReturnType = TestsetCreateType &
  TenantedDBRecordType & {
    datacase_ids: string[];
  };

// Testcase
export interface TestcaseCreateType {
  name: string;
  dataset_id: string;
  inputs: { [key: string]: any };
}

export interface TestcaseUpdateType {
  name?: string;
  dataset_id?: string;
  inputs?: { [key: string]: any };
}

export type TestcaseReturnType = TenantedDBRecordType & TestcaseCreateType;

// Evaluatorconfig
export type EvaluatorconfigCreateType = {
  name: string;
  config: CriteriaTypes;
  pipeline_spec: PipelineSpec | null;
  derives_from?: string;
};

export type EvaluatorconfigUpdateType = {
  name?: string;
  config?: CriteriaTypes;
  pipeline_spec: PipelineSpec | null;
  derives_from?: string;
};

export type EvaluatorconfigReturnType = TenantedDBRecordType & EvaluatorconfigCreateType;

// Evaluatorresult
export enum ConfidenceEnum {
  LOW = "LOW",
  MED = "MED",
  HIGH = "HIGH",
}

export type EvaluatorresultUpdateType = {
  user_score?: number;
  user_comment?: string;
};

export type EvaluatorresultReturnType = TenantedDBRecordType & {
  evalcase_id: string;
  evaluatorconfig_id: string;
  score: number | null;
  confidence: ConfidenceEnum | null;
  comment: string | null;
  eval_error: string | null;
  user_score: number | null;
  user_comment: string | null;
};

// Evalset
export type EvalsetCreateType = {
  dataset_id?: string | null;
  app_id: string;
  name: string;
  version: string;
  var_matching: {
    source: VarSourceType;
    value: string;
  }[];
};

export type EvalsetUpdateType = {
  dataset_id?: string; // TODO: Can't be changed after creation?
  app_id?: string; // TODO: Can't be changed after creation?
  name?: string;
  var_matching?: {
    // TODO: Can't be changed after creation?
    source: VarSourceType;
    value: string;
  }[];
  annotation?: string;
  launched?: boolean; // TODO: Can't be changed by UI?
  error?: string | null; // TODO: Can't be changed by UI?
  results_summary?: string | null; // TODO: Can't be changed by UI?
};

export type EvalsetReturnType = TenantedDBRecordType &
  EvalsetCreateType & {
    annotation: string;
    launched: boolean;
    error?: string | null;
    results_summary?: string | null;

    progress: number;
    app_name: string;
    evalcase_ids: string[];
    app_api_key: string;
  };

// Criteria Config Types
export interface CriteriaConfigType {
  type: CriteriaSelectorEnum;
  viable?: boolean;
}

export interface NoConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.NO_CRITERIA;
}

export interface VectorSimilarityConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.VECTOR_SIMILARITY;
  metric?: string | null;
  model?: string | null;
  label?: string | null;
}

export interface ExactMatchConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.EXACT_MATCH;
  label?: string | null;
  case_sensitive?: boolean;
}

export interface JsonConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.JSON;
}

export interface AiGradedSimilarityConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.AI_GRADED_SIMILARITY;
  label?: string | null;
}

export interface AiGradedCustomConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.AI_GRADED_CUSTOM;
  prompt?: string | null;
}

export interface AiGradedFormulaConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.AI_GRADED_FORMULA;
  criteria?: string | null;
  scoring_rubric?: { [key: string]: any } | null;
  top_answer?: string | null;
  num_repeats?: number | null;
}

export interface RagContextFaithfulnessConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.RAG_CONTEXT_FAITHFULNESS;
}

export interface RagAnswerRelevanceConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.RAG_ANSWER_RELEVANCE;
}

export interface RagContextRecallConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.RAG_CONTEXT_RECALL;
  label?: string | null;
}

export interface RagContextPrecisionConfigType extends CriteriaConfigType {
  type: CriteriaSelectorEnum.RAG_CONTEXT_PRECISION;
  label?: string | null;
}

export type CriteriaTypes =
  | NoConfigType
  | VectorSimilarityConfigType
  | ExactMatchConfigType
  | JsonConfigType
  | AiGradedSimilarityConfigType
  | AiGradedCustomConfigType
  | AiGradedFormulaConfigType
  | RagContextFaithfulnessConfigType
  | RagAnswerRelevanceConfigType
  | RagContextRecallConfigType
  | RagContextPrecisionConfigType;

// Evalcase
export interface EvalcaseCreateType {
  evalset_id: string;
  datacase_id?: string | null;
  output?: any | null;
  run_error?: string | null;
  output_stream_incomplete: boolean;
}

export interface EvalcaseReturnType extends TenantedDBRecordType {
  evalset_id: string;
  datacase_id: string | null;
  output?: any | null;
  run_error?: string | null;
  output_stream_incomplete: boolean;
  evaluatorconfig_ids: string[];
  evaluatorresult_ids: string[];

  app_name: string;
  app_api_key: string;
  app_id: string;
}

export type VersionDetails = {
  first_created: string;
  last_active: string;
  is_live: boolean;
  version: string;
  parameters: ParameterTypes[];
  docstring: string | null;
};

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { PlusIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { DropdownCase, DropdownOption } from "../primitives/Dropdown";
import { DropdownDivider } from "../primitives/Dropdown";
import { ValidLoadSpec, AppChoice, AppChoiceSectionNames, LoadMethod, DemoValuesLoadSpec } from "./controllers";
import { AppCreateType, AppReturnType, TenantType } from "../../utils/interfaces";
import { App } from "../../utils/model";
import { TenantContext } from "../../utils/TenantContext";

interface AppSelectorDropdownProps {
  userApps: AppReturnType[] | null;
  selectedApp: AppReturnType | null;
  inputAppChoices: AppChoice[] | null;
  setAppLoadSpec: (spec: ValidLoadSpec) => void;
}

const loadApp = (
  userApps: App[],
  app: AppChoice,
  activeTenant: TenantType,
  setAppLoadSpec: (x: ValidLoadSpec) => void,
) => {
  const userApp = userApps?.find((userApp) => userApp.api_key === app.api_key);
  if (userApp) {
    setAppLoadSpec({
      appId: userApp.id,
      version: userApp.latest_version,
      loadMethod: LoadMethod.APP_DEMO_VALUES,
    } as DemoValuesLoadSpec);
  } else {
    if (!activeTenant) {
      return;
    }

    App.create(
      {
        name: app.app_name,
        api_key: app.api_key,
      } as AppCreateType,
      activeTenant.id,
    ).then((newApp) => {
      setAppLoadSpec({
        appId: newApp.id,
        version: newApp.latest_version,
        loadMethod: LoadMethod.APP_DEMO_VALUES,
      } as DemoValuesLoadSpec);
    });
  }
};

export const AppSelectorDropdown: React.FC<AppSelectorDropdownProps> = ({
  userApps,
  selectedApp,
  inputAppChoices,
  setAppLoadSpec,
}) => {
  const history = useHistory();

  const activeTenant = useContext<TenantType | null>(TenantContext);

  const appChoices = inputAppChoices || [];

  return (
    <DropdownCase
      label={selectedApp != null ? selectedApp.name : "Select App"}
      className="btn-sm btn-primary text-sm w-56 capitalize"
    >
      {appChoices
        .filter((app) => app.section_name === AppChoiceSectionNames.NO_APPS)
        .map((app) => (
          <DropdownOption
            key={app.api_key}
            className="btn-sm text-sm"
            selected={selectedApp != null && app.api_key === selectedApp.api_key}
            onClick={() => {
              if (!activeTenant || !userApps) {
                return;
              }
              loadApp(userApps, app, activeTenant, setAppLoadSpec);
            }}
          >
            <div className="truncate">{app.app_name}</div>
          </DropdownOption>
        ))}
      <DropdownDivider label="My Apps" />
      {appChoices
        .filter((app) => app.section_name === AppChoiceSectionNames.USER_APPS)
        .map((app) => (
          <DropdownOption
            key={app.api_key}
            className="btn-sm text-sm"
            selected={selectedApp != null && app.api_key === selectedApp.api_key}
            onClick={() => {
              const userApp = userApps?.find((userApp) => userApp.api_key === app.api_key);
              if (userApp) {
                setAppLoadSpec({
                  appId: userApp.id,
                  version: userApp.latest_version,
                  loadMethod: LoadMethod.APP_DEMO_VALUES,
                } as DemoValuesLoadSpec);
              }
            }}
          >
            <div className="truncate">{app.app_name}</div>
          </DropdownOption>
        ))}

      <DropdownOption
        key="Create with Replit"
        className="btn-sm text-sm row flex-row justify-between items-center"
        onClick={() => window.open("https://replit.com/@composo/Composo-Hello-World-Python#main.py", "_blank")}
      >
        Create with Replit <ArrowTopRightOnSquareIcon className="w-5 h-5" />
      </DropdownOption>

      {/* Link new app option */}
      <DropdownOption
        key="link new"
        className="btn-sm text-sm row flex-row justify-between items-center"
        onClick={() => history.push("/project/")}
      >
        Link New <PlusIcon className="w-5 h-5" />
      </DropdownOption>

      <DropdownDivider label="Demo Apps" />
      {appChoices
        .filter((app) => app.section_name === AppChoiceSectionNames.DEMO_APPS)
        .map((app) => (
          <DropdownOption
            key={app.api_key}
            className="btn-sm text-sm"
            selected={selectedApp != null && app.api_key === selectedApp.api_key}
            onClick={() => {
              if (!activeTenant || !userApps) {
                return;
              }
              loadApp(userApps, app, activeTenant, setAppLoadSpec);
            }}
          >
            <div className="truncate">{app.app_name}</div>
          </DropdownOption>
        ))}
    </DropdownCase>
  );
};

import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { logMessage } from "../../utils/ApiRequest";
import { Breadcrumbs } from "../../presentation_components/primitives/Breadcrumbs";
import { useHistory } from "react-router-dom";
import { User } from "../../utils/model";
import { UserType } from "../../utils/interfaces";
import { tenantedRouteBuilder } from "../../utils/tenantManager";

export default function ProfileSettings() {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [localFirstName, setLocalFirstName] = useState<string>("");
  const [user, setUser] = useState<UserType | null>(null);

  const history = useHistory();

  useEffect(() => {
    User.fetchMe().then((response) => {
      setUser(response);
    });
  }, []);

  useEffect(() => {
    setLocalFirstName(user?.first_name || "");
  }, [user]);

  const handleNameChange = () => {
    if (user) {
      User.update(user.id, { first_name: localFirstName }).then((response) => {
        setUser(response);
      });
    }
  };

  const handlePasswordChange = async () => {
    // Validate newPassword for length and complexity
    if (!newPassword.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)) {
      toast.error("Password must be at least 8 characters long, including a number and a symbol.");
      return;
    }

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, currentPassword, newPassword);
      toast.success("Password changed successfully");
    } catch (error: any) {
      console.error("Password change error:", error);

      if (error.message.includes("LimitExceededException")) {
        toast.error("Too many password change requests, please try again after some time.");
      } else {
        toast.error("Password change error");
      }

      logMessage("error", `Failed to change password. Error: ${error.message}`, "username");
    }
  };

  return (
    <div className="platform-container">
      <div className="flex flex-col">
        <Breadcrumbs
          routes={[
            { name: "Home", href: tenantedRouteBuilder("/") },
            {
              name: "Profile Settings",
              href: tenantedRouteBuilder("/profilesettings"),
            },
          ]}
          appendArrow={false}
        />

        {/* Account Settings Header */}
        <div className="text-2xl font-bold pb-6 pt-8">
          {user?.first_name ? user.first_name + "'s " : ""}Profile Settings
        </div>

        {/*Change Name Section*/}
        <div className="max-w-lg">
          <label className="text-lg text-maindarkgray block mb-2">Change your name</label>
          <input
            type="text"
            value={localFirstName}
            onChange={(e) => setLocalFirstName(e.target.value)}
            className="border rounded w-full py-2 px-3"
            placeholder={!localFirstName ? "Enter your name" : ""}
          />
          <div className="text-right">
            <button onClick={handleNameChange} className="text-blue-500 hover:underline focus:outline-none mt-2">
              Save
            </button>
          </div>
        </div>
        {/* Change Password Section */}
        <div className="max-w-lg">
          <label className="text-lg text-maindarkgray block mb-2">Change your password</label>
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="border rounded w-full py-2 px-3 mb-2"
            placeholder="Current Password"
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="border rounded w-full py-2 px-3"
            placeholder="New password"
          />
          <div className="text-right">
            <button onClick={handlePasswordChange} className="text-blue-500 hover:underline focus:outline-none mt-2">
              Save new password
            </button>
          </div>
        </div>

        <div className="space-y-12 mt-8">
          <button
            onClick={() => {
              Auth.signOut().then(() => history.push("/"));
            }}
            className="text-blue-500 hover:underline focus:outline-none mt-2"
          >
            Sign Out
          </button>
          <p className="text-sm">If you wish to delete your account, please send an email to support@composo.ai.</p>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import AppRouter from "./AppRouter";

// Some tasty providers
import { Amplify, Auth, Hub } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

import { ToastContainer, Slide, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Amplify.configure(awsExports);
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    mandatorySignIn: true,
  },
});

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        theme="light"
      />

      <AppRouter />
    </React.StrictMode>
  );
};

export default App;

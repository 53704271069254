import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider, { SliderProps } from "@material-ui/core/Slider";
import React, { FC } from "react";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const CustomSlider = withStyles({
  root: {
    width: "100%",
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "2px solid black",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 4,
    background: "transparent",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: "linear-gradient(to right, #267df6, #f17721)",
    opacity: 0.8,
  },
})(Slider);

interface TemperatureSliderProps extends Omit<SliderProps, "onChange"> {
  temperature: number | null;
  setTemperature: (value: number) => void;
  allowableMin: number;
  allowableMax: number;
  step: number;
  className?: string;
  disabled?: boolean;
}

export const TemperatureSlider: FC<TemperatureSliderProps> = ({
  temperature,
  setTemperature,
  allowableMin,
  allowableMax,
  step,
  className,
  disabled,
}) => {
  const classes = useStyles();

  if (temperature == null) {
    // setTemperature((allowableMin + allowableMax) / 2);
    return null;
  }

  return (
    <div>
      <div className="w-full flex flex-row justify-center items-center">{temperature}</div>
      <CustomSlider
        className={`${classes.root} ${className} overflow-x-visible`}
        value={temperature}
        step={step}
        min={allowableMin}
        max={allowableMax}
        onChange={(e, newValue) => setTemperature(newValue as number)}
        disabled={disabled}
      />
    </div>
  );
};
